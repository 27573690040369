@font-face {
  font-family: "Cera Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Cera-Pro-Regular-subset.woff2) format("woff2");
}
@font-face {
  font-family: "Cera Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Cera-Pro-Bold-subset.woff2) format("woff2");
}
html {
  box-sizing: border-box;
  text-size-adjust: none;
  scroll-behavior: smooth;
  font-family: Cera Pro, Avenir, Trebuchet MS, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--darkgrey);
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
}

audio,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

a {
  color: inherit;
}

input {
  font-family: inherit;
}

:root {
  --white: #F5F5F5;
  --yellow: #EBC501;
  --dark-blue: #21273C;
}

.homepage {
  color: var(--white);
}
@media (max-width: 47.9rem) {
  .homepage {
    background: var(--yellow);
  }
}
.homepage p {
  font-size: 1.125rem;
  line-height: 1.5rem;
}
@media (min-width: 64rem) {
  .homepage p {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.homepage .stack {
  padding-top: 50vw;
  padding-bottom: 3rem;
}
@media (min-width: 48rem) {
  .homepage .stack {
    padding-top: 6rem;
  }
}
@media (min-width: 64rem) {
  .homepage .stack {
    padding: 2.5rem 0 0;
  }
}
@media (min-width: 80em) {
  .homepage .stack {
    padding: 6rem 0 0;
  }
}
.homepage .inner {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media (min-width: 48rem) {
  .homepage .inner {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (min-width: 64rem) {
  .homepage .inner {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media (min-width: 80em) {
  .homepage .inner {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
}
@media (max-width: 47.9rem) {
  .homepage .wrapper {
    padding-bottom: 1rem;
  }
}
@media (min-width: 64rem) {
  .homepage .wrapper {
    padding-bottom: 2.5rem;
  }
}
.homepage .wrapper > * + * {
  margin-top: 0.75rem;
}
@media (min-width: 64rem) {
  .homepage .wrapper > * + * {
    margin-top: 1.5rem;
  }
}
.homepage .wrapper h1 {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 100%;
  text-transform: uppercase;
  max-width: 15ch;
}
@media (min-width: 64rem) {
  .homepage .wrapper h1 {
    font-size: 4.5rem;
  }
}
.homepage .wrapper p {
  max-width: 28ch;
}
.homepage .timer {
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
}
@media (max-width: 22.4rem) {
  .homepage .timer {
    gap: 1rem;
  }
}
.homepage .timer span {
  display: block;
  font-size: 3rem;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 64rem) {
  .homepage .timer span {
    font-size: 4.5rem;
  }
}
.homepage .timer span > span:first-of-type {
  line-height: 100%;
}
.homepage .timer span > span:last-of-type {
  font-size: 0.75rem;
  line-height: 100%;
  color: var(--yellow);
}
@media (min-width: 64rem) {
  .homepage .timer span > span:last-of-type {
    font-size: 1.25rem;
  }
}
@media (min-width: 48rem) {
  .homepage .form-wrapper .top-image {
    display: none;
  }
}
@media (max-width: 47.9rem) {
  .homepage .form-wrapper .content {
    background: var(--yellow);
    color: var(--dark-blue);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
@media (max-width: 63.9rem) {
  .homepage .form-wrapper .content {
    padding-top: 1rem;
  }
}
@media (min-width: 48rem) {
  .homepage .form-wrapper .content p {
    max-width: 28ch;
  }
}
.homepage .form-wrapper .logo {
  width: 10.75rem;
  margin: 2rem 0;
}
@media (min-width: 48rem) {
  .homepage .form-wrapper .logo {
    display: none;
  }
}
.homepage form {
  width: 100%;
  display: flex;
  margin: 1rem 0;
}
@media (min-width: 48rem) {
  .homepage form {
    max-width: 34ch;
    margin: 1.25rem 0;
  }
}
@media (min-width: 64rem) {
  .homepage form {
    max-width: 40ch;
  }
}
.homepage form input {
  height: 3rem;
  border: none;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0 1rem;
}
@media (min-width: 64rem) {
  .homepage form input {
    font-size: 1.125rem;
    line-height: 2rem;
  }
}
.homepage form input:first-of-type {
  width: 100%;
  border-radius: 0.25rem 0 0 0.25rem;
}
.homepage form input:last-of-type {
  background: var(--dark-blue);
  color: var(--white);
  font-weight: 700;
  flex: 1;
  border-radius: 0 0.25rem 0.25rem 0;
}
@media (min-width: 48rem) {
  .homepage form input:last-of-type {
    background-color: var(--yellow);
    color: var(--dark-blue);
  }
}
@media (min-width: 64rem) {
  .homepage footer {
    padding-top: 2.5rem;
    font-size: 1.125rem;
    line-height: 2rem;
  }
}
.homepage .logo-large {
  position: fixed;
  bottom: 0;
  right: 0;
}
@media (max-width: 47.9rem) {
  .homepage .logo-large {
    display: none;
  }
}
.homepage .logo-large img:first-of-type {
  width: 32rem;
}
@media (min-width: 80rem) {
  .homepage .logo-large img:first-of-type {
    width: 41rem;
  }
}
.homepage .logo-large img:last-of-type {
  position: absolute;
  z-index: 10;
  right: 3.5rem;
  bottom: 1rem;
  width: 13rem;
}
@media (min-width: 80rem) {
  .homepage .logo-large img:last-of-type {
    right: 5.75rem;
    bottom: 2rem;
    width: 13rem;
  }
}
.homepage .background-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}
@media (max-width: 47.9rem) {
  .homepage .background-image {
    height: 160vw;
  }
}
@media (min-width: 48rem) {
  .homepage .background-image {
    height: 100vh;
    position: fixed;
  }
}
